/**!
 * SAYAX v1.0alpha0 (https://gatesenerji.com.tr/)
 * Copyright 2010 - 2020 GAMA Holding A.Ş.
 * Autor Gökçe ASOVA <gokce.asova@gama.com.tr>
 * UNLICENSED
 */

 const AjaxHandler = {
  fetchText(url) {
    return new Promise((resolve, reject) => {
      const request =  window.fetch( url, {
        method: 'POST',
        credentials: "same-origin",
        cache: 'no-cache',
      })
      .then(response => response.text())
      .then( text => {
        return text
      })
      .catch((error) => {
        // Silence is golden 
      })
      resolve(request)
    })
  },
}

export default AjaxHandler