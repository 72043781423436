const TRANSITION_END = 'transitionend'

const reflow = element => element.offsetHeight

const triggerTransitionEnd = element => {
  element.dispatchEvent(new Event(TRANSITION_END))
}

const emulateTransitionEnd = (element, duration) => {
  let called = false
  const durationPadding = 5
  const emulatedDuration = duration + durationPadding
  function listener() {
    called = true
    element.removeEventListener(TRANSITION_END, listener)
  }

  element.addEventListener(TRANSITION_END, listener)
  setTimeout(() => {
    if (!called) {
      triggerTransitionEnd(element)
    }
  }, emulatedDuration)
}


const getTransitionDurationFromElement = element => {
  if (!element) {
    return 0
  }

  // Get transition-duration of the element
  let {
    transitionDuration,
    transitionDelay
  } = window.getComputedStyle(element)

  const floatTransitionDuration = parseFloat(transitionDuration)
  const floatTransitionDelay = parseFloat(transitionDelay)

  // Return 0 if element or transition duration is not found
  if (!floatTransitionDuration && !floatTransitionDelay) {
    return 0
  }

  // If multiple durations are defined, take the first
  transitionDuration = transitionDuration.split(',')[0]
  transitionDelay = transitionDelay.split(',')[0]

  return (parseFloat(transitionDuration) + parseFloat(transitionDelay)) * MILLISECONDS_MULTIPLIER
}

const getSelector = element => {
  let selector = element.getAttribute('data-target')

  if (!selector || selector === '#') {
    const hrefAttr = element.getAttribute('href')

    selector = hrefAttr && hrefAttr !== '#' ? hrefAttr.trim() : null
  }
  //console.log("selector", selector)
  return selector
}

const getElementFromSelector = element => {
  const selector = getSelector(element)
  //console.log("getElementFromSelector", selector)
  return selector ? document.querySelector(selector) : null
}


export {
  TRANSITION_END,
  getTransitionDurationFromElement,
  getElementFromSelector,
  triggerTransitionEnd,
  emulateTransitionEnd,
  reflow,
}
