/*!
 ** BundleJS
 ** UNLICENSED
 ** Copyright: 2021, GAMA Holding A.Ş.
 **
*/

import Reveal from './js/reveal'
import Preloader from './js/preloader'

//import AnchorX from './js/anchorx'

export default {
  Reveal,
  Preloader,
//  AnchorX,
}
