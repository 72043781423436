const mapData = (() => {
  const storeData = {}
  let id = 1
  return {
    set(element, key, data) {
      if (typeof element.bsKey === 'undefined') {
        element.bsKey = {
          key,
          id
        }
        id++
      }

      storeData[element.bsKey.id] = data
    },
    get(element, key) {
      if (!element || typeof element.bsKey === 'undefined') {
        return null
      }
      const keyProperties = element.bsKey
      if (keyProperties.key === key) {
        return storeData[keyProperties.id]
      }
      return null
    },
    delete(element, key) {
      if (typeof element.bsKey === 'undefined') {
        return
      }

      const keyProperties = element.bsKey
      if (keyProperties.key === key) {
        delete storeData[keyProperties.id]
        delete element.bsKey
      }
    }
  }
})()

const DataHandler = {
  setData(instance, key, data) {
    try {
      mapData.set(instance, key, data)
    }
    catch (e) {
      //
    }
  },
  getData(instance, key) {
    return mapData.get(instance, key)
  },
  removeData(instance, key) {
    mapData.delete(instance, key)
  }
}

export default DataHandler
