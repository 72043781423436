/*!
 ** Reveal.js v1.0.0
 **
 ** UNLICENSED
 ** Copyright: 2021, GAMA Holding A.Ş.
 **
*/

import AjaxHandler from './helpers/ajax-handler'
import DataHandler from './helpers/data-handler'
import EventHandler from './helpers/event-handler'
import Manipulator from './helpers/manipulator'
import SelectorEngine from './helpers/selector-handler'
import {
  TRANSITION_END,
  reflow,
  getElementFromSelector,
  getTransitionDurationFromElement,
  emulateTransitionEnd,
} from './helpers/utils'

const NAME =  'reveal'
const VERSION = '1.0.0'
const DATA_KEY = 'gh.reveal'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const EVENT_HIDDEN = `closed${EVENT_KEY}`
const EVENT_SHOW = `open${EVENT_KEY}`

const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`
const EVENT_SCROLL_WINDOW = `scroll${EVENT_KEY}${DATA_API_KEY}`

const SELECTOR_DATA_TOGGLE = '[data-toggle="reveal"]'
const SELECTOR_DATA_LOAD_WITH = '[data-load-with="ajax"]'

const SELECTOR_PANEL_BODY = '.body-ajax'
const SELECTOR_PANEL_CLOSE = '.panel-out'
const SELECTOR_SUBNAV_BACKDROP = '.overlay-mask'

const CLASS_NAME_FADE = 'animate'


class Reveal {
  constructor(el, config) {
    this._url  = (el != null)?el.href:null
    this.isTransitioning = false
    this.element = el
    this.isShown = false
    this._backdrop = null
    this._ajaxBody = SelectorEngine.findOne(SELECTOR_PANEL_BODY)
    this._element = el
    this._config = config

    DataHandler.setData(el, DATA_KEY, this)

  }

  static get VERSION() {
    return VERSION
  }

  show(relatedTarget) {
    let transition = relatedTarget.classList.contains(CLASS_NAME_FADE)
    let HasChildren = relatedTarget.getAttribute('data-child')
    let TargetId = this._config.target
    let SubMnu = SelectorEngine.findOne(TargetId)
    let AllSubs = SelectorEngine.find(".sub-wrapper", document)
    let SubContainers = SelectorEngine.find("div.sub-container", document)
    let OverlayMask = SelectorEngine.findOne(".overlay-mask")

    if (this.isTransitioning) {
      return
    }

    if (HasChildren === "false") {
      window.location = relatedTarget.href;
      this.hide();
      return
    }

    if (relatedTarget.classList.contains(CLASS_NAME_FADE)) {
      this.isTransitioning = true
    }

    EventHandler.trigger(this.element, EVENT_SHOW, {
      relatedTarget
    })

    this.isShown = true

    relatedTarget.removeAttribute('aria-hidden')
    relatedTarget.setAttribute('aria-modal', true)
    relatedTarget.setAttribute('role', 'dialog')

    if (transition) {
      reflow(relatedTarget)
    }

    OverlayMask.style.display = "block";
    OverlayMask.classList.add("show-overlay");

    SubMnu.classList.add("open")
    SubContainers.forEach(function(sub) {
      sub.classList.remove("active")
    })
    SubMnu.closest("div.sub-container").classList.add("active");
    //SubMnu.style.display = "block"

    AllSubs.forEach(function(sub) {
      let id = sub.getAttribute("id")
      if (id != TargetId.replace("#","")) {
        sub.classList.remove("open")
        //sub.style.display = "none"
      }
    })


  }

  hide(relatedTarget) {
    let AllSubs = SelectorEngine.find(".sub-wrapper", document)
    let OverlayMask = SelectorEngine.findOne(".overlay-mask", document)

    if (this.isTransitioning) {
      return
    }

    OverlayMask.classList.remove("show-overlay");
    OverlayMask.style.display = "none";

    AllSubs.forEach(function(sub) {
      sub.classList.remove("open")
      sub.closest("div.sub-container").classList.remove("active")
    })


  }

  open(relatedTarget) {
    this.hide(relatedTarget)

    reflow(this._ajaxBody)
    const backdropTransitionDuration = getTransitionDurationFromElement(this._ajaxBody)
    EventHandler.one(this._ajaxBody, TRANSITION_END, async () => {
      const parser = new DOMParser()
      history.replaceState({}, '', this._url);
      AjaxHandler.fetchText(this._url).then( text => {
        const bda = parser.parseFromString(text, "text/html")
        this._ajaxBody.innerHTML =  bda.querySelector(".body-ajax").innerHTML
        setTimeout(function() {
          var textWrapper = document.querySelector('.cover-title');
          var textWrapper = false;
          if (textWrapper) {
            textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
            textWrapper.style.opacity = "1";
            anime.timeline({loop: false})
              .add({
                targets: '.cover-title .letter',
                translateY: [-100,0],
                easing: "easeOutExpo",
                duration: 1400,
                opacity:[0,1],
                delay: (el, i) => 30 * i
              })
          }
        }, 500)
        const cover = document.getElementsByClassName('parallax-img');
        new simpleParallax(cover);
      }, false)
    })
    emulateTransitionEnd(this._ajaxBody, backdropTransitionDuration)
  }

}

EventHandler.on(document, EVENT_CLICK_DATA_API, SELECTOR_DATA_TOGGLE, function (event) {
  const target = getElementFromSelector(this)
  const subnavBackdrop = SelectorEngine.findOne(SELECTOR_SUBNAV_BACKDROP, target)

  if (this.tagName === 'A' || this.tagName === 'AREA') {
    event.preventDefault()
  }


  EventHandler.one(target, EVENT_SHOW, showEvent => {
    if (showEvent.defaultPrevented) {
      // only register focus restorer if modal will actually get shown
      return
    }

    EventHandler.one(target, EVENT_HIDDEN, () => {
      if (isVisible(this)) {
        this.focus()
      }
    })
  })

  let data = DataHandler.getData(target, DATA_KEY)
  if (!data) {
    const config = {
      ...Manipulator.getDataAttributes(target),
      ...Manipulator.getDataAttributes(this)
    }

    data = new Reveal(target, config)
  }

  data.show(this)


})

EventHandler.on(document, EVENT_CLICK_DATA_API, SELECTOR_DATA_LOAD_WITH, function (event) {
  const target = this

  if (this.tagName === 'A') {
    event.preventDefault()
  }

  let data = DataHandler.getData(target, DATA_KEY)
  if (!data) {
    const config = {
      ...Manipulator.getDataAttributes(this)
    }

    data = new Reveal(target, config)
  }

  data.open(this)


})


EventHandler.on(document, EVENT_CLICK_DATA_API, SELECTOR_PANEL_CLOSE, function (event) {
  const target = getElementFromSelector(this)

  if (this.tagName === 'A' || this.tagName === 'AREA') {
    event.preventDefault()
  }

  let data = DataHandler.getData(target, DATA_KEY)
  if (!data) {
    const config = {
      ...Manipulator.getDataAttributes(target),
      ...Manipulator.getDataAttributes(this)
    }

    data = new Reveal(target, config)
  }

  data.hide(this)

})

EventHandler.on(window, EVENT_SCROLL_WINDOW, null, function (event) {
  var scrollSize = window.scrollY;
  var item = document.getElementsByClassName("gamanavbar")[0];
  if (scrollSize > 3) {
    item.classList.add("BgColor");
  }
  if (scrollSize < 3) {
    item.classList.remove("BgColor");
  }

})


export default Reveal
