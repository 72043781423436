/*!
 ** Preloader.js v1.0.0
 **
 ** UNLICENSED
 ** Copyright: 2021, GAMA Holding A.Ş.
 **
 */

import EventHandler from './helpers/event-handler'
import SelectorEngine from './helpers/selector-handler'

const NAME =  'preolader'
const VERSION = '1.0.0'
const DATA_KEY = 'gh.preloader'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const SESSION_VAR_DONTSHOW = "doNotShow"

const EVENT_DOMREADY = `readystatechange${EVENT_KEY}${DATA_API_KEY}`


class Preloader {
  constructor(el, config) {
    this._body = SelectorEngine.findOne("body")
    this._document = document
  }

  static get VERSION() {
    return VERSION
  }

  static get NAME() {
    return NAME
  }

  init() {
    let pre = SelectorEngine.findOne(".preloader")

    if ( this._document.readyState !== "complete" ) {
      this._body.style.display = "none";
      if ( !sessionStorage.getItem( SESSION_VAR_DONTSHOW )) {
        pre.style.visibility = "visible";
        pre.style.opacity = "1";
      }
      else {
        pre.style.visibility = "hidden";
        pre.style.opacity = "0";
      }
    }
    else {
      this._body.style.display = "flex";
      setTimeout(function() {
        pre.style.visibility = "hidden";
        pre.style.opacity = "0";
        sessionStorage.setItem( SESSION_VAR_DONTSHOW, 'true' );
      }, 1000);
      setTimeout(function() {
        var ct = document.getElementById("CoverTitleReveal");
        if (ct) {
          ct.classList.add("reveal");
        }
      }, 1500);
    }

  }


}


EventHandler.on(document, EVENT_DOMREADY, null, function (event) {

  const pre = new Preloader();
  pre.init();

})


export default  Preloader
